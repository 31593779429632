.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ccEFvh {
  background: #212529 !important;
}

.content {
  background-color: black;
  background-size: cover;
  height: 100vh;
  position: absolute;
  bottom: 0rem;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-repeat: no-repeat;
}

.App-link {
  color: #61dafb;
}

.text-content {
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
  line-height: 96.4%;
  text-align: center;

  color: #ffffff;
}
.text-content2 {
  /* font-family: "Baskerville Display PT"; */
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
  line-height: 96.4%;
  text-align: center;

  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateY(100%);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0%);
}

.slide-exit-active {
  transform: translateY(-100%);
}

.containerSection {
  overflow-x: hidden !important ;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
  max-width: 100% !important;
  padding: 0 !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.containerSection::-webkit-scrollbar {
  display: none;
}

.chatBotMenu {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  right: 20px !important;
  bottom: 100px;
  width: 50px;
  z-index: 2;
  /* Firefox */
  @-moz-document url-prefix() {
    right: 20px !important;
  }

  /* Chrome */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    right: 50px !important;
  }
}
.css-79ws1d-MuiModal-root {
  z-index: 1 !important;
}
