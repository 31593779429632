@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.aUFBackGround {
  color: white !important;
  width: 100%;
  /* height: 100vh; */
}
.aUFt1 {
  font-style: normal;
  font-weight: 700;
  font-family: "Montserrat";
  line-height: 55px;
  text-align: center;
  padding-top: 60px;
  font-size: 3.6vw;
}
.aUFt2 {
  font-family: "Montserrat";
  font-weight: 500;
}
.aUFbutton {
  background-color: white;
  color: #393839;
  border-color: white;
  width: 14vw;
  height: 3vw;
  font-size: 1.2vw;
  font-weight: 500;
  border-radius: 0%;
  margin-top: 40px;
  margin-bottom: 20px;
  border: none;
}

.aUFbutton:hover {
  background-color: blue;
  color: white;
}

.textFooterContentSocialPages:hover {
  color: white !important ;
}
.textFooterLinkPages:hover {
  color: white !important ;
}

.footerContainerDown {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.footerContainerPadding {
  padding-right: 120px;
  padding-left: 120px;
}
.footerContainerPaddingTD {
  padding-top: 30px;
  padding-bottom: 50px;
}

.textFooterLinkPages {
  padding-left: 10px;
  padding-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
}
.textFooterLinkPagesRight {
  text-align: right;
}
.textFooterLinkSocialPages {
  text-align: left;
}
.textFooterTitleSocialPages {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.textFooterContentSocialPages {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
}

.lineFooter {
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.baskervilleFooter {
  /* font-family: "Baskerville Display PT"; */
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 96.4%;
}
.dFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.dot {
  padding-left: 20px;
  padding-right: 20px;
}
.dFooter li {
  list-style-type: none;
}
.dFooter a {
  margin-top: 0;
  margin-bottom: 0rem;
  text-decoration: none;
  color: white;
}

.PD_G_logo {
  display: none;
}

.footerNav {
  /* display: flex; */
  flex-direction: row;
  float: right;
  text-align: right !important;
}
@media screen and (min-width: 769px) {
  .socialIcon {
    display: none;
  }
}

/* pantallas pequeñas */
@media screen and (max-width: 768px) {
  .aUFt1 {
    line-height: 55px;
    padding-top: 60px;
    font-size: 36px;
  }
  .aUFt2 {
    font-size: 30px;
    margin-top: -10px;
  }
  .aUFbutton {
    width: 40vw;
    height: 8vw;
    font-size: 4vw;
    font-weight: 500;
    border-radius: 0%;
    margin-top: 15px;
  }
  .footerContainerPadding {
    padding: 0.8rem;
  }
  .lineFooter {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .textFooterLinkSocialPages {
    display: none;
  }
  .footerContainerPaddingTD {
    padding-top: -4rem;
    padding-bottom: 50px;
  }
  .footerNav {
    display: block;
    text-align: left !important;
  }
  .textFooterLinkPages {
    display: block;
    margin-bottom: 0.4rem;
    text-decoration: none;
  }
  .bottomFooterContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  hr {
    display: none;
  }
  .socialIcon {
    margin: 0.4rem;
  }

  .dFooter {
    display: flex;
    flex-direction: column-reverse;
  }

  .copyRight {
    font-size: 0.8rem;
    text-align: right;
    margin-bottom: 25px;
  }

  .dot {
    display: none;
  }

  .policy {
    font-size: 0.8rem;
    text-align: right;
    margin-bottom: 0.8rem;
  }
  .PD_G_logo {
    display: initial;
    margin-bottom: 40px;
    margin-left: 10px;
    width: 15vh;
  }
}
