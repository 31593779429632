@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.aUTBackGround {
  background-image: url(../../../assets/background2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding-top: 15%;
  color: white;
}

.T1 {
  margin-top: auto;
}

.T1_1 {
  font-family: "Baskerville";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 96.4%;
  text-align: center;
}

.T1_2 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 80px;
  line-height: 96.4%;
  text-align: center;
}

.T1_3 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 80px;
  line-height: 96.4%;
  text-align: center;
}

.aUP1Line {
  width: 350px;
}

.T2 {
  font-weight: 300;
  font-size: 25px;
}

/* Para pantalla mobile */

/* pantallas pequeñas */
@media screen and (max-width: 768px) {
  .T1 {
    margin-top: 40vw;
    margin-bottom: 5vw;
  }
  .T1_1 {
    font-family: "Montserrat";
    font-style: normal;
    font-size: 36px;
  }
  .T1_2 {
    font-size: 36px;
  }

  .T1_3 {
    font-size: 36px;
  }

  .aUP1Line {
    display: none;
  }
}
