#vacanteAplicada {
  display: none;
}
.mainContainer {
  height: 100% !important;
  width: 100% !important;
  background-color: white;
}

.formContainer {
  color: black;
  background-color: white !important;
}
.formCarrersTitle {
  font-weight: 700;
  text-align: left;
  padding-top: 60px;
  padding-bottom: 8%;
}
.formCarrersTitleRequired {
  text-align: right;
  padding-top: 100px;
  padding-bottom: 70px;
}
.formCarrearsInputs {
  margin-bottom: 30px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom-color: black !important;
}
.formCarrearsInputs:focus {
  outline: none !important;
}
.formCarrearLabels {
  float: left !important;
}
.buttonContainer {
  width: 100%;
  display: grid;
  justify-content: flex-end;
}
.formCarrersButton {
  width: 252px;
  font-weight: 700;
  margin-top: 40px;
  float: right;
  margin-bottom: 100px;
  border-radius: 0px;
  background-color: black;
  border-bottom-color: none !important;
  border-color: black;
}
.formCarrersRequired {
  color: #d93a34 !important;
}
.mainForm {
  width: 100%;
  /* justify-content: space-between; */
}
.divider {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Adjuntar, Dropbox o soltar aquí";
  display: inline-block;
  border: 0;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.container {
  max-width: 100% !important;
  padding-left: 10%;
  padding-right: 10%;
}

/* Animacion de carga */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .formCarrersButton {
    margin-bottom: 150px;
  }
}
