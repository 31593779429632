body {
  height: 100vh;
  position: absolut !important ;
  bottom: 0rem;
  width: 100% !important;
  background-repeat: no-repeat;
}

.titleContent {
  padding-top: 15%;
  height: 100vh;
  position: absolut !important ;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 145.9%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.titleDif {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 145.9%;
  text-align: center;
  text-transform: uppercase;
}

.imageArrow1 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
}

/* Estilos para pantallas medianas */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .titleContent {
    padding-top: 20%;
  }
}
/* pantallas pequeñas */
@media screen and (max-width: 768px) {
  .titleContent {
    padding-top: 50%;
    padding-left: 10%;
    padding-right: 10%;
  }
  .title {
    font-size: 18px !important;
  }
  .titleDif {
    font-size: 18px !important;
  }
  .imageArrowSize1 {
    width: 35%;
    padding-left: 5%;
  }
}
