@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.aUEquipoBackGround {
  color: white;
  width: 100%;
  height: 100vh;
  margin-top: 5vw;
}

.aUETitleText {
  font-family: "Montserrat";
  font-weight: bold;
  margin-top: 1vw;
}

.aUETitleLine {
  width: 12vw;
  margin-bottom: 4vw;
}

.aUEContent {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 5vw;
}

.aUEPart1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: justify;
}

.aUEimage_2 {
  display: none;
}

.aUE_Creative {
  width: 27vw;
}

.aUET2 {
  text-align: start;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 2.8vw;
}

.aUET3 {
  font-family: "Inter";
  font-size: 1vw;
}

.aUEPart2 {
  margin-left: 10vw;
}

.aUEimage {
  width: 40vw;
}

/* Para pantalla mobile */

@media screen and (min-width: 769px) {
  .socialIcon {
    display: none;
  }
}

/* pantallas pequeñas */
@media screen and (max-width: 768px) {
  .aUETitleText {
    font-size: 14vw;
    margin-top: 15vw;
    /* margin-bottom: 15vw; */
  }

  .aUETitleLine {
    display: none;
  }
  .aUEimage_2 {
    display: flex;
    width: 80vw;
    margin-top: 15vw;
    margin-bottom: 15vw;
  }
  .aUE_Creative {
    width: 80vw;
  }
  .aUET2 {
    text-align: start;
    font-size: 8vw;
  }
  .aUET3 {
    text-align: start;
    font-size: 4.4vw;
  }
  .aUEPart2 {
    display: none;
  }
}
