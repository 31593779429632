body {
  background-color: black !important;
}

.titleContents2 {
  padding-top: 15%;
  background-color: black !important;
  background-size: cover;
  height: 100vh;
  position: absolut !important ;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 145.9%;

  /* or 70px */
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}
.imageArrow2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.barraDiv {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.brandsNav {
  padding-right: 45px;
  padding-left: 45px;
}
#footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 20px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .imageArrowSize2 {
    width: 30%;
    padding-left: 5%;
  }
  .titleContents2 {
    padding-top: 40%;
    padding-left: 10%;
    padding-right: 10%;
  }
}
