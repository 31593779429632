.section {
  scrollbar-width: none !important; /*Firefox*/
}

/*Webkit(Chrome, Android browser, Safari, Edge...)*/
section::-webkit-scrollbar {
  width: 0;
}
.backgroundCarrersAvalible {
  background-color: white;
  background-size: cover;
  overflow: none;
}
.empleoTitle {
  text-decoration: none;
  padding-top: 4rem;
  font-weight: 700;
  font-size: 60px;
}
.empleoDescTitle {
  font-weight: 400;
}
.carrersTitle:hover {
  color: #0d6efd !important;
}
.carrersLink {
  padding-left: 12%;
  padding-right: 20px;
}
.carrersLink a:hover {
  color: black !important;
}

.carrersLink a {
  text-decoration: none; /* quita la línea inferior */
  color: black;
  border-bottom: none; /* quita cualquier estilo de borde inferior */
  outline: none; /* quita cualquier contorno predeterminado */
}
.departmentTitle {
  font-weight: 600;
  line-height: 59px;
  padding-bottom: 5%;
  padding-top: 5%;
}
.carrersTitle {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  margin-bottom: none;
}
.carrersDescription {
  font-size: 15px;
}

.empleos-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.empleos-list li {
  flex-basis: calc(50% - 0.3rem);
  max-width: calc(50% - 0.3rem);
  margin-bottom: 1rem;
}
.formatJob {
  text-align: left;
}
.job-order {
  padding-bottom: 10px;
  padding-top: 15px;
}
