@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
.container-home {
  position: relative;
}
.video-size-home {
  height: 100vh;
  width: 100%;
  object-fit: fill;
}
.text-contents {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  width: 100%;
  text-align: left;
  padding-left: 70px;
}

.title-homeContent {
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 96.4%;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
}

.description-homeContent {
  color: white;
  font-weight: 400;
  font-size: 13px;
  padding-bottom: 20px;
}
@media (max-width: 768px) {
  .video-size-home {
    height: 100%;

    object-fit: fill;
  }
  .text-contents {
    padding-left: 50px;
  }
  .em {
    font-size: 2.5rem;
    margin-right: 0px;
  }

  .content-container {
    margin-top: -30% !important;
    align-items: center !important;
    margin-top: -1rem;
    padding-right: -12%;
  }

  .title-homeContent {
    font-size: 40px !important;
  }
  .description-homeContent {
    font-size: 11px !important;
    line-height: 20px !important;
  }
}

@media (max-width: 576px) {
  .icon-container-home {
    padding-bottom: 20px;
  }
  .button-homeContent {
    width: 150px !important;
    height: 40px !important;
  }
  .text-contents {
    padding-left: 40px;
  }
  .title-homeContent {
    font-size: 25px !important;
  }

  .description-homeContent {
    font-size: 11px !important;
    line-height: 20px !important;
  }
}

.button-homeContent {
  width: 200px;
  height: 40px;
  background-color: white;
  border-radius: 0%;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  /* identical to box height */
  font-variant: all-small-caps;

  color: #393839;
}

.h2-font-home {
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 96.4%;
  font-family: "Montserrat";
}

.video-container-home {
  position: relative;
  padding-bottom: 56.25%; /* Ajusta el valor según las dimensiones del video */
}

.lineDefillo img {
  background-image: url("/src/assets/lineadefillo.svg");
}

.chatbotAnimado {
  display: flex;
  flex-direction: column;
  /* align-items:; */
  animation: moveUpDown 1s ease infinite;
  text-align: center;
  /* align-items: center; */
  position: absolute;
  right: 35px !important;
  bottom: 40px;
  width: 50px;
  z-index: 2;
  /* Firefox */
  @-moz-document url-prefix() {
    right: 50px !important;
  }
  /* Chrome */
  /* @media screen and (-webkit-min-device-pixel-ratio: 0) {
    right: 35px !important;
  } */
  /* Chrome */
  /* @media screen and (min--moz-device-pixel-ratio: 0) {
    right: 50px !important;
  } */
  
}
@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* @media screen and (max-width: 920px) {
  .chatbotAnimado {
    margin-right: 4px;
  }
}
@media screen and (max-width: 820px) {
  .chatbotAnimado {
    margin-right: 6px;
  }
}
@media screen and (max-width: 520px) {
  .chatbotAnimado {
    margin-right: 10px;
  }
}
@media screen and (max-width: 350px) {
  .chatbotAnimado {
    margin-right: 12px;
  }
} */

.gradient-video::before {
  content: "";
  color: aliceblue;
  height: 100%;
  width: 125%;
  display: flex;
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: -12.5%;

  background: rgb(0, 0, 0);
  background: linear-gradient(
      90deg,
      rgb(0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 12%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 70%,
      rgba(0, 0, 0, 1) 88%,
      rgb(0, 0, 0) 100%
    ),
    linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 5%,
      rgba(255, 255, 255, 0) 15%
    ) !important;
}
