section {
  scrollbar-width: none; /*Firefox*/
}

/*Webkit(Chrome, Android browser, Safari, Edge...)*/
section::-webkit-scrollbar {
  width: 0;
}
.textHome2 {
  color: white !important;
}
.textTitleHome2 {
  padding-bottom: 3%;
  font-size: 30px;
  font-weight: 700;
}
.backgroundCarrersHome2 {
  background-color: black;
  background-size: cover;
  padding-top: 10%;
  height: 100vh;
}

.imageArrowDownHome2 {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}
.homeCarrearContentLeft {
  display: flex;
  align-items: flex-start;
  padding-bottom: 4%;
}
.imageHomeLeft {
  display: flex;
  align-items: flex-start;
  padding-left: 10%;
}

.textHomeLeft {
  flex: 1;
  padding: 10px;
  font-size: 14px !important;
  text-align: left !important;
  padding-right: 10%;
  padding-left: 4%;
  align-self: center;
}
.homeCarrearContentRight {
  display: flex;
  align-items: flex-start;
  padding-bottom: 5% !important;
}
.imageHomeRight {
  display: flex;
  align-items: flex-start;
  padding-right: 10%;
}

.textHomeRight {
  flex: 1;
  padding: 10px;
  font-size: 14px !important;
  text-align: left !important;
  padding-right: 5%;
  padding-left: 10%;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .homeCarrearContentLeft {
    flex-direction: column;
  }
  .textHomeLeft {
    padding-left: 10%;
  }
  .imageHomeLeft {
    align-self: center;
    padding-left: 0%;
    width: 90% !important;
    padding-bottom: 10px;
  }
  .homeCarrearContentRight {
    flex-direction: column-reverse;
  }
  .imageHomeRight {
    align-self: center;
    padding-right: 0%;
    width: 90% !important;
    padding-bottom: 10px;
  }
}
