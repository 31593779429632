.section {
  overflow-y: scroll !important ;
  overflow-x: hidden !important ;
  max-height: 100vh;
}

.richText > a:only-child {
  color: white !important;
}
.carrersFontDetail {
  color: white !important;
  text-align: left;
  padding-left: 25%;
  padding-right: 25%;
}

.carrersPreTitle {
  padding-top: 11rem;
  text-align: center;
}
.CarrersTitleDetail {
  font-size: 5rem;
  font-weight: 700;
  line-height: 96.4%;
  text-align: center !important
    ;
  padding-bottom: 70px;
}
.rainBowBar {
  text-align: center !important;
  padding-bottom: 20%;
}
.carrersTitleDescriptionDetail {
  font-weight: bold;
}
.carrersParagraphDetails {
  text-align: justify;
}
.carrersDescriptionLine {
  white-space: pre-line !important;
}

.carrersDetailButton {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 10rem;
  padding-top: -20px;
}

@media screen and (max-width: 768px) {
  .CarrersTitleDetail {
    font-size: 2rem;
  }
  .carrersFontDetail {
    padding-left: 10%;
    padding-right: 10%;
  }
}
