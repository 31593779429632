.backgroundCarrersHome {
  background-image: url("../../assets/carrersBackground.png");
  background-size: cover;
  padding-top: 15%;
  height: 100vh;
  z-index: -1;
  color: white;
}
.backgroundCarrersHome::-webkit-scrollbar {
  display: none;
}

.carrersHomeTitleFirst {
  font-family: "Baskerville Display PT" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 96.4%;
  text-align: center;
}
.carrersHomeTitleSecond {
  font-weight: 700;
  font-size: 60px;
  line-height: 96.4%;
  text-align: center;
}

.carrersHomeDescriptionText {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.carrersHomeSubDescriptionText {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}
.carrersHomeBoldSubDescriptionText {
  font-weight: 800;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}

.imageArrow1 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
}
.carrearButtonHome {
  border-radius: 0%;
  margin-top: 20px;
  background-color: white;
  color: black;
  font-weight: 500;
  width: 252px;
  border-color: white;
}

@media screen and (max-width: 768px) {
  .carrersHomeTitleFirst {
    font-size: 40px;
  }
  .carrersHomeTitleSecond {
    font-size: 40px !important;
  }
  .carrersHomePadding {
    padding-top: 30%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.flechaCarrearHome {
  display: flex;
  flex-direction: column;
  /* align-items:; */
  animation: moveUpDown 1s ease infinite;
  text-align: center;
  /* align-items: center; */
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 50px;
  z-index: 2;
  padding-top: 10px !important;
}
