@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.aUIndexBackGround {
  color: white;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aUIP1elipse {
  width: 40vw;
}

.aUIP1word {
  display: none;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: start;
}

.aUIP2List {
  text-align: start;
  margin-top: 10vw;
  margin-left: 6vw;
  font-size: 1vw;
}

.aUIP2Lnumber {
  text-decoration: underline;
  margin-right: 10px;
}

.aUIP2Lname {
  margin-right: 30px;
}

/* Para pantalla mobile */

@media screen and (min-width: 769px) {
  .socialIcon {
    display: none;
  }
}

/* pantallas pequeñas */
@media screen and (max-width: 768px) {
  .aUIndexPart1 {
    display: none;
  }
  .aUIP1word {
    display: flex;
    margin-bottom: 20vw;
    font-size: 5vw;
  }
  .aUIP2List {
    margin-top: 0vw;
    margin-left: 0vw;
    font-size: 4vw;
  }
  .aUI_arrow {
    display: none;
  }
}
