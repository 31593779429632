.video-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vimeo-wrapper .vpip-button {
  display: none !important;
}

.video-list:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(to right, rgb(0 0 0), rgb(0 0 0 / 0%));
}

@media (min-width: 768px) {
  .video-wrapper {
    border: none;
    margin: 20px;
    flex-basis: calc(33.33% - 40px);
  }
}
@media screen and (max-width: 768px) {
  .video-list {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .video-list {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
  }
}
.videoTextContainer {
  position: absolute;
  text-align: left;
  bottom: 39%;
  left: 10px;
  color: #fff;
  padding-left: 5%;
  align-content: center;
  z-index: 9999;
}

.preTitleVideo {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.titleVideo {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.descriptionVideo {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.buttonTextVideo {
  margin-top: 7%;
  height: 50px;
  width: 182px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border-color: none;
  border: none !important;
  background: rgba(97, 94, 94, 0.49);
  color: #fff;
}

@media screen and (max-width: 768px) {
  .titleVideo {
    font-size: 18px;
  }
  .descriptionVideo {
    font-size: 13px;
  }
  .effie-image {
    margin-top: 50%;
  }
}

@media screen and (max-width: 1024) {
  .effie-image {
    width: 100% !important;
    height: 100% !important;
    max-height: 100vh !important;
    margin-top: 50%;
  }
}
.effie-image {
  width: 100%;

  height: 100%;
  max-height: 100vh;
}
