input:valid {
  border-bottom: 1px solid #8cc543;
  box-shadow: none;
}
input:placeholder-shown:not(:focus) {
  border-bottom: 2px solid #c4c4c433; /* Establece un borde gris */
}
textarea:valid {
  border: 1px solid #8cc543;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* height: 60px; ajusta el valor según la altura de tu Navbar */
  z-index: 99999;
  background-color: #000000b0;
}

.sidenav {
  font-weight: bold;
  height: 100%;
  /* Establecer el ancho del sidenav */
  position: fixed;
  /* Establecer la posición fija para que el sidenav no se desplace con el desplazamiento de la página */
  z-index: 1;
  /* Establecer una capa de índice z más alta que el contenido de la página para que el sidenav aparezca en la parte superior */
  top: 0;
  /* Establecer la posición superior del sidenav en la parte superior de la ventana */
  right: 0;
  /* Establecer la posición derecha del sidenav en la parte derecha de la ventana */
  background-color: #ffffff;
  /* Establecer el color de fondo del sidenav */
  overflow-x: hidden;
  /* Ocultar la barra de desplazamiento horizontal del sidenav */
  padding-top: 60px;
  /* Añadir un relleno superior al sidenav para dejar espacio para el encabezado de la barra de navegación */
}

.sidenav a {
  text-align: left;

  padding: 10px 8px 10px 10%;
  /* Añadir un relleno a los elementos de la lista del sidenav */
  text-decoration: none;
  /* Eliminar la decoración de subrayado de los elementos de la lista del sidenav */
  font-size: 18px;
  /* Establecer el tamaño de fuente de los elementos de la lista del sidenav */
  color: #000000;
  /* Establecer el color de fuente de los elementos de la lista del sidenav */
  display: block;
  /* Convertir los elementos de la lista del sidenav en bloques para que ocupen todo el ancho del sidenav */
}

.sidenav a:hover {
  color: #0d6efd;
  /* Cambiar el color de fuente de los elementos de la lista del sidenav cuando se pasa el mouse sobre ellos */
}
.options :focus {
  color: #0d6efd;
}
.contactHover :hover {
  transform: scale(1.2);
}
@media screen and (max-width: 768px) {
  .sidenav {
    width: 100% !important;
  }
}
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  z-index: 999;
  font-weight: bold;
}
.close-button-DontShow {
  width: 0;
}
.sideNavText {
  padding-left: 30px;
  padding-right: 30px;
}
.infoContact {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 30px;
  color: #949799;
}

.menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
}

.column {
  flex: 1 !important;
}
.row {
  width: auto;
}
.formTitle {
  margin-bottom: 6%;
  font-size: 22.5px;
  text-align: left;
}
.formContent {
  width: 100%;
  display: grid;
}
.buttonForm {
  margin-bottom: 10px !important;
  border-radius: 0%;
  background: #393839;
  font-style: normal;
  border: none;
  font-weight: 600;
  width: 100px;
  height: 3rem;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 25px;
}
.input {
  margin-top: 1%;
  margin-bottom: 4%;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  border-color: #f3f3f3;
}
.input:focus {
  border-color: #1fbbee;
}
.formTextArea {
  margin-top: 1rem;
  border-color: none !important;
  resize: none;
}

.mi-elemento {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.navbar-dark {
  color: white important;
}

.optionLink:hover > .arrowNavBar {
  width: 10px;
  margin-left: 10px;
  display: inline-flex !important;
}
.arrowNavBar {
  display: none;
}

/* Agregado del mapa en el hamburger menu*/

.mapIcon {
  padding-left: 10px;
  padding-bottom: 5px;
  width: 20px;
}

.mapa:hover {
  margin-left: 10px;
}
