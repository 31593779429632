@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.aboutUsValoresBackGround {
  width: 100%;
  height: 100vh;
  color: white;
}

.aUVTitle {
  margin-top: 4vw;
  margin-bottom: 2vw;
}

.aUVTitleImage {
  width: 2vw;
  margin-bottom: 0.2vw;
}

.aUVTitleText {
  font-weight: bold;
  font-size: 2.5vw;
  font-family: "Inter";
}

.aUVText {
  margin-left: -30% !important;
  margin-right: -30% !important;
  align-content: center;
  display: flex;
  text-align: start !important;
  flex-direction: column;
  font-size: 20px;
  font-family: "Montserrat";
}
.aUVDetails {
  text-align: justify;
}
.aUVSubTitle {
  font-weight: bold;
}

/* Para pantalla mobile */

/* pantallas pequeñas */
@media screen and (max-width: 768px) {
  .aUVTitleImage {
    display: none;
  }

  .aUVTitleText {
    margin-top: 20vw;
    font-size: 8vw;
  }

  .aUVText {
    font-size: 11.7px;
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
}
