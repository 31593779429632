.content::before {
  content: "";
  height: 100%;
  width: 155%;
  display: flex;
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: -12.5%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 60%,
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0) 100%
      )
      90deg,
    rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 12%, rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 1) 88%,
    rgba(0, 0, 0, 0) 100%;
}
