@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.aUPBackGround {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  font-family: "Montserrat";
}

.aUPMision {
  font-size: 2vw;
}

.aUPit1,
.aUPit2 {
  font-weight: 700;
}

/* Para pantalla mobile */

@media screen and (min-width: 769px) {
  .socialIcon {
    display: none;
  }
}

/* pantallas pequeñas */
@media screen and (max-width: 768px) {
  .aUPMision {
    font-size: 7vw;
    text-align: start;
    font-weight: 600;
    margin-left: 3vw;
  }
  .aUPit1,
  .aUPit2 {
    font-weight: 600;
  }
}
