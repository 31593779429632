.footerColor {
  color: #ffffff !important;
}
.textFooterContentSocialPages:hover {
  color: white !important ;
}
.textFooterLinkPages:hover {
  color: white !important ;
}
.footerTitleText {
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  line-height: 35px;
  text-align: center;
  padding-top: 80px;
  font-size: 35px
}
.footerSubTitleText {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  text-decoration-line: underline !important;
  padding-left: 3px;
}
.footerContainerDown {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.footerContainerPadding {
  padding-right: 120px;
  padding-left: 120px;
}
.footerContainerPaddingTD {
  padding-top: 2vw;
  padding-bottom: 50px;
}

.textFooterLinkPages {
  padding-left: 10px;
  padding-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
}
.textFooterLinkPagesRight {
  text-align: right;
}
.textFooterLinkSocialPages {
  text-align: left;
}
.textFooterTitleSocialPages {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.textFooterContentSocialPages {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
}

.lineFooter {
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top: 5vw;
}
.baskervilleFooter {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 3vw;
}
.dFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.dot {
  padding-left: 20px;
  padding-right: 20px;
}
.dFooter li {
  list-style-type: none;
}
.dFooter a {
  margin-top: 0;
  margin-bottom: 0rem;
  text-decoration: none;
  color: white;
}

.PD_G_logo {
  display: none;
}

.footerNav {
  flex-direction: row;
  float: right;
  text-align: right !important;
}

.memberLogo{
  max-width: 180px;
}
@media screen and (min-width: 769px) {
  .socialIcon {
    display: none;
  }
}

/* pantallas pequeñas */
@media screen and (max-width: 768px) {
  .footerTitleText {
    font-style: normal;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    line-height: 44px;
    text-align: center;
    padding-top: 80px;
    font-size: 34px
  }

  .membersTitle{
    line-height: 40px;
    padding-top: 50px;
    font-size: 24px;
  }
  .footerSubTitleText {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    text-align: center;
    text-decoration-line: underline !important;
    padding-left: 3px;
  }
  .footerContainerPadding {
    padding: 0.8rem;
  }
  .lineFooter {
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 20vw;
  }
  .textFooterLinkSocialPages {
    display: none;
  }
  .footerContainerPaddingTD {
    padding-top: -4rem;
    padding-bottom: 5vw;
  }
  .footerNav {
    display: block;
    text-align: left !important;
  }
  .textFooterLinkPages {
    display: block;
    margin-bottom: 0.2rem;
    text-decoration: none;
    font-size: 15px;
  }
  .bottomFooterContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  hr {
    display: none;
  }
  .socialIcon {
    margin: 0.4rem;
    width: 20px;
  }

  .dFooter {
    display: flex;
    flex-direction: column-reverse;
  }

  .copyRight {
    font-size: 0.8rem;
    text-align: right;
    margin-bottom: 25px;
  }

  .dot {
    display: none;
  }

  .policy {
    font-size: 0.8rem;
    text-align: right;
    margin-bottom: 0.4rem;
    cursor: pointer;
  }
  .PD_G_logo {
    display: initial;
    margin-bottom: 10px;
    margin-left: 10px;
    width: 15vh;
  }
  .memberLogo{
    max-width: 120px;
  }
  .membersContainer{
    display: grid;
    grid-column: 2;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    display: none;
  }
  .footerRow{
    margin-bottom: -4rem;
  }
  .footerTitleText {
    font-style: normal;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    line-height: 24px;
    text-align: center;
    padding-top: 80px;
    font-size: 24px;
  }
  .footerRow{
    margin-bottom: -6rem;
    
  }
  .baskervilleFooter {
    font-weight: bolder;
    font-size: 1.6vw;
    line-height: 1.6vw;
  }
}

@media screen and (max-width: 330px) {
  .footerTitleText {
    font-style: normal;
    line-height: 1.6vw;
    text-align: center;
    padding-top: 0px;
    font-size: 9vw;
  }
  .baskervilleFooter {
    font-weight: bolder;
    font-size: 2vw;
    line-height: 2vw;
  }
  .footerSubTitleText {
    font-style: normal;
    font-weight: 400;
    font-size: 8vw;
    line-height: 39px;
    text-align: center;
    text-decoration-line: underline !important;
    padding-left: 3px;
  }
  .lineFooter {
    padding-bottom: 0px;
    margin-bottom: 5vw;
    margin-top: 20vw;
  }
}
